export const XboxLoginUrl = 'https://login.live.com/logout.srf?ru=https%3A%2F%2Flogin.live.com%2Foauth20_authorize.srf%3Fclient_id%3D2c7953d8-4eb0-4131-a9e6-d08054d103dc%26redirect_uri%3Dhttps%253A%252F%252Fxm.coplay.io%252Fcode%252F%26response_type%3Dcode%26scope%3DXboxLive.signin%2520XboxLive.offline_access';

export const FirebaseConfig = {
  "apiKey": "AIzaSyA3PvXKlc7vFypf6oHt2CeiVnXXao0NVcU",
  "authDomain": "coplay-oauth-prod.firebaseapp.com",
  "projectId": "coplay-oauth-prod",
  "storageBucket": "coplay-oauth-prod.appspot.com",
  "messagingSenderId": "892503087502",
  "appId": "1:892503087502:web:0eaba1f453784ba1fc6cdf",
  "measurementId": "G-5Z0XDKZKH9"
};

export const BackendConfig = {
  "date": "10/16/2024, 3:59:08 PM",
  "environment": "prod",
  "xboxGameSearchUrl": "https://eumdlazj7xb4awvtwuz2ly5bh40qhzwz.lambda-url.us-west-1.on.aws/",
  "firebaseId": "coplay-oauth-prod",
  "thirdPartyUrl": "https://ssmugfhw6onas64a7cno7nygdq0sfghz.lambda-url.us-west-1.on.aws/",
  "scheduleGroupName": "CustomScheduleGroup-prod",
  "schedulerRoleArn": "arn:aws:iam::367627898123:role/ProdBackendStack-schedulerRoleprodid997AC389-hIUd6ZD7JY1r",
  "scheduleLambdaArn": "arn:aws:lambda:us-west-1:367627898123:function:ProdBackendStack-xboxSchedHandlerprod7AD99FBA-SSM3IcE2hVSq",
  "url": "https://5bv2axvdcat4xhycug7icbdp4u0iiqyd.lambda-url.us-west-1.on.aws/"
};